<template>
    <div class="okraina-register">

        <!--Индикатор загрузки-->
        <transition>
            <okraina-loader-page v-show="loading" />
        </transition>

        <!-- Ошибка загрузки -->
        <template v-if="loadingError || loading">
            <div class="text-center">
                Ошибка загрузки данных.
                <br /><br />
                <div class="btn btn1" @click="retryLoad">Повторить</div>
            </div>
        </template>

        <template v-if="!loading || !loadingError">

            <div v-if="step == 1" class="step-1">

                <h2>Личные данные</h2>
                <template v-for="field in fieldsCommon" :key="field.CODE">
                    <div class="field"
                        :class="{ 'form-group': field.TYPE == 'radio', 'form-group-error': errors.fields[field.CODE] }">

                        <div class="label" v-if="field.TYPE == 'radio'">
                            {{ field.NAME }}
                            <span v-if="field.REQUIRED == 'Y'">*</span>
                        </div>

                        <!--Текстовое поле-->
                        <template v-if="field.TYPE == 'text'">
                            <okraina-control-input :label="field.NAME" :required="field.REQUIRED == 'Y'" :type="'text'"
                                v-model="values.fields[field.CODE]" v-model:error="errors.fields[field.CODE]"
                                v-model:valid="valids.fields[field.CODE]" :mask="getFieldMask(field)"
                                :inputMode="field.CODE == 'EMAIL' ? 'email' : 'text'" />

                        </template>

                        <!--Дата-->
                        <template v-if="field.TYPE == 'date'">
                            <okraina-control-input :label="field.NAME" :required="field.REQUIRED == 'Y'" :type="'date'"
                                v-model="values.fields[field.CODE]" v-model:error="errors.fields[field.CODE]"
                                v-model:valid="valids.fields[field.CODE]" />

                        </template>

                        <!-- Radio-переключатель -->
                        <template v-if="field.TYPE == 'radio'">
                            <div class="values.fields">
                                <div class="value" v-for="value in field.VALUES" :key="value.ID">
                                    <okraina-control-radio :label="value.NAME" :required="field.REQUIRED == 'Y'"
                                        v-model="values.fields[field.CODE]" v-model:error="errors.fields[field.CODE]"
                                        v-model:valid="valids.fields[field.CODE]" :value="value.ID" />
                                </div>
                            </div>
                        </template>

                    </div>
                </template>

                <template v-if="fieldsPassword.length > 0">
                    <h2>Задать пароль</h2>
                    <template v-for="field in fieldsPassword" :key="field.CODE">
                        <!--Пароль-->
                        <template v-if="field.TYPE == 'password'">
                            <okraina-control-input :label="field.NAME" :required="field.REQUIRED == 'Y'"
                                :type="showPassword[field.CODE] ? 'text' : 'password'"
                                v-model="values.fields[field.CODE]" v-model:error="errors.fields[field.CODE]"
                                v-model:valid="valids.fields[field.CODE]"
                                :description="field.CODE == 'USER_CONFIRM_PASSWORD' ? this.passwordRequirements : ''">

                                <template v-slot:right>
                                    <div v-if="!showPassword[field.CODE]" class="action"
                                        @click="onShowPasswordClick(field.CODE)">
                                        <span class="icon fi fi-eye"></span>
                                    </div>
                                    <div v-else class="action" @click="onHidePasswordClick(field.CODE)">
                                        <span class="icon fi fi-eye-close"></span>
                                    </div>
                                </template>

                            </okraina-control-input>
                        </template>
                    </template>
                </template>

                <template v-if="useCaptcha">
                    <h2>Защита регистрации</h2>
                    <okraina-control-input v-model="captchaWord" type="text" label="Слово на картинке"
                        :captcha="captchaURL" :required="true" v-model:error="errors.captcha"
                        v-model:valid="valids.captcha" />
                </template>

                <div class="btn-wrap">
                    <div class="btn btn-block btn1" :class="{ 'wait': saving1 }" v-ripple="'rgba(255, 255, 255, 0.15)'"
                        @click="register">
                        Зарегистрироваться
                        <div v-if="saving1" class="btn-loader"></div>
                    </div>
                </div>

            </div>

            <div v-else-if="step == 2" class="step-2">
                <h2>Введите код подтверждения</h2>

                <div class="notes">
                    Код подтверждения отправлен на Ваш номер телефона. Код действителен в течении {{ expire }}-х минут.
                </div>
                <br />

                <div class="codes">

                    <template v-for="index in [0, 1, 2, 3, 4]" :key="index">
                        <div class="text-center">
                            <okraina-control-input class="code" type="tel" label="" :required="true"
                                v-model="values.code[index]" v-model:error="errors.code[index]"
                                v-model:valid="valids.code[index]" :mask="{ mask: '9' }" :ref="setCodeRef"
                                @input="(value) => { onCodeInput(index, value) }"
                                @paste="(e) => { onCodePaste(index, e) }" />
                        </div>
                    </template>

                </div>

                <div class="btn-wrap">
                    <div class="btn btn-block btn1" :class="saving2 ? 'wait' : ''" v-ripple @click="onConfirmBtnclick">
                        Подтвердить
                        <div v-if="saving2" class="btn-loader"></div>
                    </div>
                </div>

                <div class="step-back">
                    <div class="a" @click="onBackClick">попробовать с другими данными</div>
                </div>

                <template v-if="!canReceiveAgain">
                    <div class="timer text-center" v-if="left > 0">
                        Повторная отправка кода возможна через {{ left }} сек.
                    </div>
                </template>
                <template v-else>
                    <br />
                    <h2>Получить повторно</h2>

                    <okraina-control-input v-if="useCaptcha" v-model="captchaWord" type="text" label="Слово на картинке"
                        :captcha="captchaURL" :required="true" v-model:error="errors.captcha"
                        v-model:valid="valids.captcha" />

                    <div class="btn-wrap">
                        <div class="btn btn-block btn3" :class="saving1 ? 'wait' : ''" v-if="canReceiveAgain" v-ripple
                            @click="register">
                            Получить
                            <div v-if="saving1" class="btn-loader"></div>
                        </div>
                    </div>

                </template>


            </div>

            <div v-else-if="step == 3" class="step-3">

                <div class="success">

                    <div class="title">Спасибо за регистрацию!</div>

                    <div class="description" v-if="bonus > 0 && isNew">
                        Вы получили +{{ bonus }}<br />
                        бонусов на свой счёт
                    </div>

                    <div class="bonus" v-if="bonus > 0 && isNew">
                        <span class="value">+{{ bonus }}</span>
                        <span class="icon fi fi-money"></span>
                    </div>

                    <a class="btn btn1 btn-block" href="/catalog/" v-ripple
                        @click.prevent="goToCatalog('/catalog/')">Перейти в
                        каталог</a>
                </div>

            </div>

        </template>

    </div>

</template>


<script>
import okrainaControlInput from "@/components/controls/input.vue";
import okrainaControlRadio from "@/components/controls/radio.vue";
import okrainaLoaderPage from "@/components/loader.page.vue";

import rest from "@/plugins/rest";
import bus from "@/plugins/bus";
import tools from "@/plugins/tools";

import { mapGetters } from "vuex";

export default {
    name: "okraina-register",
    components: {
        okrainaControlInput,
        okrainaControlRadio,
        okrainaLoaderPage,
    },
    emits: ["onRegister"],
    data() {
        return {
            loading: false,
            loadingError: false,
            saving1: false,
            saving2: false,
            showPassword: {},

            step: 1,

            fields: [],
            values: {
                fields: {},
                code: [
                    "",
                    "",
                    "",
                    "",
                    ""
                ],
            },
            errors: {
                fields: {},
                captcha: false,
                code: [
                    false,
                    false,
                    false,
                    false,
                    false
                ]
            },
            valids: {
                fields: {},
                captcha: true,
                code: [
                    true,
                    true,
                    true,
                    true,
                    true
                ]
            },

            useCaptcha: false,
            captchaWord: "",
            captchaSid: "",
            captchaURL: "",

            codeRefs: [],
            expire: 0,
            left: 0,
            timer: 0,
            canReceiveAgain: false,

            passwordRequirements: "",
            isNew: false,
            bonus: 0
        };
    },
    computed: {

        ...mapGetters({
            isAuthorized: "isAuthorized",
            connection: "getConnection"
        }),

        fieldsCommon() {
            return this.fields.filter(f => f.CODE != 'USER_PASSWORD' && f.CODE != 'USER_CONFIRM_PASSWORD')
        },
        fieldsPassword() {
            return this.fields.filter(f => f.CODE == 'USER_PASSWORD' || f.CODE == 'USER_CONFIRM_PASSWORD')
        },

    },
    methods: {

        /**
        * Загружает данные
        */
        loadData() {

            if (this.loading) {
                return;
            }

            this.loading = true;

            rest
                .call("auth.register.info", {
                    method: "post",
                    data: {
                    },
                })
                .then((data) => {
                    this.loadingError = !data.SUCCESS;
                    if (data.SUCCESS) {

                        this.fields = data.FIELDS;

                        this.passwordRequirements = data.PASSWORD_REQUIREMENTS;
                        this.useCaptcha = data.USE_CAPTCHA == "Y";
                        this.captchaSid = data.CAPTCHA_SID ? data.CAPTCHA_SID : "";
                        this.captchaURL = data.CAPTCHA_URL ? data.CAPTCHA_URL : "";

                        this.resetValues();
                        this.restoreData();

                    } else {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    }
                })
                .finally(() => {
                    this.loading = false;
                });

        },

        /**
        * Повторная попытка загрузки
        */
        retryLoad() {
            this.loadData();
        },

        /**
        * Регистрация
        */
        register() {
            if (this.saving1) {
                return;
            }

            //првоерка введённых данных
            if (!this.checkValuesStep1()) {
                return;
            }
            this.saving1 = true;

            rest
                .call("auth.register", {
                    method: "post",
                    data: {
                        FIELDS: this.values.fields,
                        CAPTCHA_WORD: this.captchaWord,
                        CAPTCHA_SID: this.captchaSid,
                        sessid: this.connection.session.bitrix_sessid,
                    },
                })
                .then((data) => {

                    //если требуется капча
                    if (data.CAPTCHA_SID) {
                        this.useCaptcha = true;
                        this.captchaSid = data.CAPTCHA_SID;
                        this.captchaURL = data.CAPTCHA_URL;
                    }

                    //если ошибка входа
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        //обработка успешной отправки СМС
                        this.onStep1Success(data);
                    }
                })
                .finally(() => {
                    this.saving1 = false;
                });

        },

        /**
        * Сброс полей
        */
        resetValues() {
            this.values.fields = {};

            this.fields.forEach(f => {

                this.values.fields[f.CODE] = "";
                this.valids.fields[f.CODE] = false;
                this.errors.fields[f.CODE] = false;

            });
        },

        /**
        * Проверяет значения Шаг 1
        */
        checkValuesStep1(showErrors = true) {

            let error = false;

            this.fields.forEach(f => {

                error = !this.valids.fields[f.CODE];
                //если нужно показать ошибки или если у поля нет ошибок
                if (showErrors || !error) {
                    this.errors.fields[f.CODE] = error;
                }
            });

            if (this.useCaptcha) {
                error = !this.valids.captcha;
                if (showErrors || !error) {
                    this.errors.captcha = error;
                }
            }

            for (var key in this.errors.fields) {
                if (this.errors.fields[key]) {
                    return false
                }
            }
            if (this.errors.captcha) {
                return false;
            }

            return true;
        },

        /**
        * Получает маску для поля
        */
        getFieldMask(field) {
            if (field.CODE == "EMAIL" || field.CODE == "USER_EMAIL") {
                return tools.maskEmail();
            } else if (field.CODE == "PERSONAL_PHONE" || field.CODE == "USER_LOGIN") {
                return tools.maskPhone();
            }
            return null;
        },

        /**
         * Клик по иконке "показать пароль"
         */
        onShowPasswordClick(code) {
            this.showPassword[code] = true;
        },

        /**
         * Клик по иконке "скрыть пароль"
         */
        onHidePasswordClick(code) {
            this.showPassword[code] = false;
        },

        /**
         * обработка завершения шага 1
         */
        onStep1Success(data) {

            this.step = 2;
            this.captchaWord = "";
            this.bonus = data.BONUS;

            this.values.code = ["", "", "", "", ""];
            this.valids.code = [true, true, true, true, true];
            this.errors.code = [false, false, false, false, false];
            let vm = this;
            setTimeout(() => {
                vm.codeRefs[0].$refs.input.focus();
            }, 10)

            this.expire = data.EXPIRE ? Math.round(parseInt(data.EXPIRE) / 60) : 3;
            let timeout = data.TIMEOUT ? parseInt(data.TIMEOUT) : 10;

            this.startTimer(timeout);
            this.canReceiveAgain = false;

        },

        /**
         * Запоминает ссылки на элементы сообщений
         */
        setCodeRef(el) {
            if (el) {
                this.codeRefs.push(el);
            }
        },

        /**
         * Обработка измнения в полях ввода кода
         */
        onCodeInput(index, value) {

            //если значение есть
            if (value) {
                //если поле не последнее
                if (index < this.values.code.length - 1) {
                    //перейдём к следующему полю
                    this.codeRefs[index + 1].$refs.input.focus();
                } else {
                    //клик по кнопке входа
                    this.onConfirmBtnclick();
                }
            }
        },

        /**
         * Обработка вставки в поле ввода
         */
        onCodePaste(index, e) {

            if (index != 0) {
                return
            }

            var clipboardData, pastedData;
            e.preventDefault();
            // Get pasted data via clipboard API
            clipboardData = e.clipboardData || window.clipboardData || e.originalEvent.clipboardData;
            pastedData = clipboardData.getData('Text').replace(/<[^>]*>/g, "");

            //пробуем вставить код во все поля ввода
            var arList = pastedData.split("");
            let vm = this;
            setTimeout(function () {
                vm.values.code.forEach((v, index) => {
                    if (arList[index]) {
                        vm.values.code[index] = arList[index]
                    }
                })

            }, 1);

        },

        /**
         * Запускает таймер обратного отсчёт до повторной отправки кода
         */
        startTimer(timeout) {

            this.left = timeout;
            let vm = this;
            this.timer = setInterval(function () {
                vm.updateTimeLeft();
            }, 1000);
        },

        updateTimeLeft() {
            this.left -= 1;

            if (this.left == 0) {
                this.allowReceiveAgain();
            }
        },

        /**
         * Разрешить получить код повторно
         */
        allowReceiveAgain() {
            if (this.timer > 0) {
                clearInterval(this.timer);
            }

            this.canReceiveAgain = true;
        },

        /**
         * Клик по кнопке "Войти"
         */
        onConfirmBtnclick() {

            if (!this.checkValuesStep2()) {
                return;
            }

            if (this.saving2) {
                return;
            }
            this.saving2 = true;

            //запрашивает автооризацию
            rest
                .call("auth.register.confirm", {
                    method: "post",
                    data: {
                        PHONE: this.values.fields.PERSONAL_PHONE,
                        CODE: this.values.code.join(""),
                        sessid: this.connection.session.bitrix_sessid,
                    },
                })
                .then((data) => {
                    //если ошибка 
                    if (!data.SUCCESS) {
                        //показываем сообщение об ошибке
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: data.ERROR_TEXT });
                    } else {
                        this.step = 3;
                        this.isNew = data.NEW == 'Y';
                        //показываем сообщение об успехе
                        bus.emit("OKRAINA_MESSAGE_E_SHOW", { message: "Вы успешно зарегистрировались.", type: "success" });
                    }
                })
                .finally(() => {
                    this.saving2 = false;
                });
        },

        /**
         * Проверяет значения
         */
        checkValuesStep2(showErrors = true) {
            let arFeilds = ["code"];

            arFeilds.forEach((field) => {

                //обычное поле
                if (!Array.isArray(this.valids[field])) {
                    let error = !this.valids[field];

                    //если нужно показать ошибки или если у поля нет ошибок
                    if (showErrors || !error) {
                        this.errors[field] = error;
                    }
                }
                //иначе массив
                else {
                    for (let i in this.valids[field]) {
                        let error = !this.valids[field][i];

                        //если нужно показать ошибки или если у поля нет ошибок
                        if (showErrors || !error) {
                            this.errors[field][i] = error;
                        }
                    }
                }

            });

            for (var i in arFeilds) {
                let key = arFeilds[i];

                //если капча не требуется, то не проверяем её
                if (key == "captcha" && !this.useCaptcha) {
                    continue;
                }

                let list = Array.isArray(this.errors[key]) ? this.errors[key] : [this.errors[key]]
                if (list.find(i => !!i)) {
                    return false
                }
            }

            return true;
        },

        /**
         * Переход в каталог
         */
        goToCatalog() {

            let vm = this;
            setTimeout(() => {
                vm.$router.replace({ path: "/catalog/" });
            }, 500);

        },

        /**
        * Шаг назад
        */
        onBackClick() {

            this.step = 1;
        },


        /**
         * Сохраняет данные для восстановления приложения из background
         */
        saveDataForRestore() {
            if (this.isAuthorized) {
                return false;
            }
            return {
                "component": this.$options.name,
                "data": {
                    step: this.step,
                    values: this.values,
                    bonus: this.bonus,
                    isNew: this.isNew
                }
            }
        },

        /**
         * Восстановление данных при восстановлении приложения из background
         */
        restoreData() {
            let restoreData = this.$store.getters.getRestoreData;
            let params = restoreData.find((p) => p.component == this.$options.name);
            if (params) {
                for (var key in params.data) {
                    let value = params.data[key];
                    this.$data[key] = value;
                }

                //стираем данные. чтобы при reload не применились опять
                restoreData = restoreData.filter(p => p != params);
                this.$store.commit("setRestoreData", restoreData);
            }
        }

    },

    /**
     * Событие перед обновлением DOM
     */
    beforeUpdate() {
        this.codeRefs = [];
    },

    activated() {
        if (this.step > 1) {
            this.step = 1;
            this.loadData();
        }
    },

    created() {
        this.loadData();

        //подписываемся на сохранение данных для восстановления приложения из background
        this.$store.commit("addRestoreDataCallback", this.saveDataForRestore);
    },
};
</script>

<style lang="scss">
.okraina-register {

    .btn-wrap {
        margin-top: 25px;
        margin-bottom: 20px;
    }

    /**
        Шаг №2 - ввод кода
    **/

    .step-2 {
        position: relative;

        .codes {
            margin: 0px -7px;
            margin-bottom: 15px;
        }

        .codes::after {
            display: block;
            content: "";
            clear: both;
        }

        .codes>div {
            float: left;
            padding: 0px 7px;
            width: 20%;
        }

        .code {
            .form-control {
                text-align: center;
                font-size: 20px;
            }

        }

        .btn-receive-again {
            margin-bottom: 30px;
        }

        .step-back {
            text-align: center;
            margin-bottom: 20px;
        }

        .timer {
            margin-bottom: 30px;
        }


    }

    /**
        Шаг 3 - успех
    */

    .step-3 {
        .success {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 250px;
            text-align: center;

            .title {
                font-size: 18px;
                font-weight: 600;
                color: var(--color-text-title);
                margin-bottom: 20px;
            }

            .description {
                font-weight: 300;
                color: var(--color-text-description);
                margin-bottom: 20px;
            }

            .bonus {
                margin-bottom: 40px;
                font-size: 40px;

                .value {
                    font-weight: bold;

                }

                .value,
                .icon {
                    background: linear-gradient(281.01deg, #2793CA 24.43%, #399DAD 30.54%, #59AF79 42.64%, #74BE4D 54.53%, #88C92C 65.96%, #97D114 76.79%, #A0D605 86.8%, #A3D800 95.18%), #FFFFFF;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }
        }
    }
}
</style>
<template>
    <okraina-sheet v-model:visible="visibleInternal" :maxHeight="90">
        <template v-slot:body>
            <div class="okraina-how-total-amount">
                <div class="sheet-title text-center">Как формируется итоговая сумма?</div>

                <div class="row">
                    <div class="text">Включена сумма товаров с учетом скидок, бонусных баллов и доставки.</div>
                    <div class="button">
                        <div class="btn btn-block btn3" @click="close">Понятно</div>
                    </div>
                </div>

            </div>
        </template>
    </okraina-sheet>
</template>

<script>
import okrainaSheet from "@/components/sheet.vue";

export default {
    name: "okraina-how-total-amount",
    components: {
        okrainaSheet
    },
    emits: ["close"],
    data() {
        return {
            visibleInternal: this.visible,
        };
    },
    props:{
        visible: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    watch:{
        visible: function () {
            this.visibleInternal = this.visible;
        },
    },
    methods: {

        /**
         * Закрывает диалог
         */
        close() {
            this.visibleInternal = false;
            this.$emit("close");
        },

    },
};
</script>

<style lang="scss">
    .okraina-how-total-amount{
        .sheet-title{
            font-weight: bold;
        }
        .button{
            padding-top: 15px;
            margin:auto;
            width: 100%;
        }
    }
</style>

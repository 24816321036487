<template>
    <div class="okraina-cart-total" v-if="items.length > 0">

        <h2>Итого</h2>

        <div class="trow" v-if="discount > 0">
            <div class="label">Ваша скидка</div>
            <div class="value discount" v-html="tools.formatPrice(discount, {
        supDecimals: false,
        supCurrency: false,
    })"></div>
        </div>

        <div class="trow price">
            <div class="label">Сумма с учетом скидки</div>
            <div class="value" v-html="tools.formatPrice(discountPrice, {
        supDecimals: false,
        supCurrency: false,
    })"></div>
        </div>

        <div class="trow">
            <div class="label">Доставка</div>
            <div class="value" v-if="deliveryPrice > 0" v-html="tools.formatPrice(deliveryPrice, {
        supDecimals: false,
        supCurrency: false,
    })"></div>
            <div class="value discount" v-else>Бесплатно</div>
        </div>

        <div class="trow" v-if="bonusSum > 0">
            <div class="label">Оплата бонусами</div>
            <div class="value discount" v-html="tools.formatPrice(bonusSum, {
        supDecimals: false,
        supCurrency: false,
    })"></div>
        </div>

        <div class="trow" v-if="cashback > 0">
            <div class="label">Ваш кешбэк</div>
            <div class="value">
                <div class="cashback">
                    + {{ cashback }}
                    <span class="icon fi fi-money"></span>
                </div>

            </div>
        </div>

        <div class="trow" v-if="valentines > 0">
            <div class="label">Ваши валентинки</div>
            <div class="value">
                <div class="valentines">
                    + {{ valentines }}
                    <span class="icon fi fi-money"></span>
                </div>

            </div>
        </div>

        <div class="trow sum" @click="showHowTotal">
            <div>
                <div class="label">К оплате</div>
                <div class="more">Подробнее</div>
            </div>

            <div class="value" v-html="tools.formatPrice(sum, {
        supDecimals: false,
        supCurrency: false,
    })"></div>
        </div>
        <okraina-how-total-amount v-model:visible="howTotal" @close="closeHowTotal" />
    </div>
</template>

<script>
import tools from "@/plugins/tools";
import okrainaHowTotalAmount from "@/components/how.total.amount.vue";

import { mapGetters } from "vuex";

export default {
    name: "okraina-cart-total",
    data() {
        return {
            tools: tools,
            howTotal:false
        }
    },
    components:{
        okrainaHowTotalAmount
    },
    props: {
        items: {
            type: Array,
            default() {
                return [];
            },
        },
        deliveryPrice: {
            type: Number,
            default() {
                return 0;
            },
        },
        cashback: {
            type: Number,
            default() {
                return 0;
            },
        },
        valentines: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    computed: {
        ...mapGetters({
            bonusUse: "getBonusUse"
        }),

        discountPrice() {
            let value = this.items.reduce(function (sum, item) {
                return sum + item.FIELDS.QUANTITY * item.PRICE.DISCOUNT_VALUE;
            }, 0);

            return value;
        },

        price() {
            let value = this.items.reduce(function (sum, item) {
                return sum + item.FIELDS.QUANTITY * item.PRICE.VALUE;
            }, 0);

            return value;
        },

        sum() {
            return this.discountPrice - this.bonusSum + this.deliveryPrice;
        },

        discount() {
            return this.price - this.discountPrice;
        },

        bonusSum() {
            if (this.bonusUse.use) {
                return this.bonusUse.value;
            }

            return 0;
        }




    },
    methods:{
        
        showHowTotal(){
            this.howTotal = true;
        },

        closeHowTotal(){
            this.howTotal = false;
        }

    }
}

</script>

<style lang="scss">
.okraina-cart-total {
    margin-top: 25px;
    padding: 0px 16px;

    .trow {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
        margin-bottom: 10px;
    }

    .value {
        font-weight: 500;
    }

    .okraina-price {
        font-size: 14px;
        font-weight: 500;
    }

    .sum {
        .label {
            font-size: 18px;
            font-weight: 600;
            color: var(--color-text-dark);
        }
        .more{
            font-size: 12px;
        }
        .okraina-price {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .discount {
        color: var(--color-discount);

        .okraina-price {
            color: var(--color-discount);
        }

    }

    .cashback,
    .valentines {
        position: relative;
        display: inline-block;
        padding: 4px 28px 4px 8px;
        border-radius: 20px;
        font-weight: 700;
        font-size: 12px;
        line-height: 1;

        background: linear-gradient(281.01deg,
                #2793ca 24.43%,
                #399dad 30.54%,
                #59af79 42.64%,
                #74be4d 54.53%,
                #88c92c 65.96%,
                #97d114 76.79%,
                #a0d605 86.8%,
                #a3d800 95.18%);

        color: #fff;

        .icon {
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            font-size: 12px;
        }
    }
}
</style>
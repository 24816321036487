<template>
  <div class="form-group" :class="{
    focused: focused,
    filled: value,
    nomargin: noBottomMargin,
    'form-group-error': error
  }">
    <template v-if="label != ''">
      <div :class="labelClass">
        {{ label }}
        <span v-if="required && needShowRequired" class="required">*</span>
      </div>
    </template>

    <div class="form-control-wrap" :class="{
    'no-slot-left': !this.$slots.left,
    'no-slot-right': !this.$slots.right,
  }">
      <div class="slot-left">
        <slot name="left"></slot>
      </div>
      <div>
        <input class="form-control" :type="type" :name="name"
          :class="classes.concat([required ? 'required' : '']).concat([slim ? 'slim' : ''])" v-model="value"
          v-maska="mask" :readonly="readonly" @input="handleInput" @focus="onFocus" @blur="onBlur" ref="input"
          autocomplete="off" :placeholder="placeholder" @change="onChange" :maxlength="maxLength"
          :inputmode="inputMode" />
      </div>
      <div class="slot-right">
        <slot name="right"></slot>
      </div>

      <img v-if="captcha" :src="captcha" class="captcha" />
    </div>

    <okraina-control-suggestions v-if="suggestions" v-model="value" :focused="focused" />

    <div v-if="description" v-html="description" class="description"></div>
  </div>
</template>

<script>
import okrainaControlSuggestions from "@/components/controls/suggestions.vue";

import vInputmask from '@/directives/inputmask';

export default {
  name: "okraina-control-input",
  directives: { maska: vInputmask },
  components: {
    okrainaControlSuggestions
  },
  data() {
    return {
      value: this.modelValue,
      focused: false,
    };
  },
  emits: ["update:modelValue", "change", "focused", "update:valid", "update:error", "input"],
  props: {
    label: {
      type: String,
      default() {
        return "";
      },
    },
    labelClass: {
      type: String,
      default() {
        return "label"
      }
    },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    name: {
      type: String,
      default() {
        return "";
      },
    },
    modelValue: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    captcha: {
      type: String,
      default() {
        return "";
      },
    },
    mask: {
      type: Object,
      default() {
        return null;
      },
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    showRequired: {
      type: Boolean,
      default() {
        return true;
      },
    },
    description: {
      type: String,
      default() {
        return "";
      },
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    error: {
      type: Boolean,
      default() {
        return false;
      },
    },
    valid: {
      type: Boolean,
      default() {
        return true
      }
    },
    placeholder: {
      type: [String, Number],
      default() {
        return "";
      },
    },
    classes: {
      type: Array,
      default() {
        return [];
      },
    },
    noBottomMargin: {
      type: Boolean,
      default() {
        return false;
      },
    },
    slim: {
      type: Boolean,
      default() {
        return false;
      },
    },
    //использовать подсказки
    suggestions: {
      type: Boolean,
      default() {
        return false;
      },
    },
    maxLength: {
      type: [Number, String],
      default() {
        return "";
      },
    },
    inputMode: {
      type: String,
      default() {
        return "text";
      },
    },
  },
  computed: {
    needShowRequired() {
      return this.required && this.showRequired;
    },
  },
  watch: {
    modelValue: function (newVal) {
      if (newVal !== this.value) {
        if (this.mask && this.$refs.input.inputmask) {
          this.$refs.input.inputmask.setValue(newVal);
        }
        this.value = newVal;
      }
    },
    value: function () {
      this.$emit("update:modelValue", this.value);
      this.checkValid();
    },
    valid: function () {
      if (this.valid) {
        this.$emit("update:error", false);
      }
    }
  },
  methods: {
    handleInput(e) {
      this.value = e.target.value;
      let vm = this;
      setTimeout(() => {
        vm.$emit("input", e.target.value);
      }, 10);

    },
    onFocus() {
      this.focused = true;
      this.$emit("focused");
    },
    onBlur() {
      this.focused = false;
    },
    onLabelClick() {
      this.focused = true;
      this.$refs.input.focus();
    },
    onChange() {
      this.$emit("change", this.value);
    },
    focus() {
      this.$refs.input.focus();
    },

    /**
     * Проверяет валидно ли значение
     */
    checkValid() {

      if (this.required && !(this.value)) {
        this.$emit("update:valid", false);
        return;
      }

      if (this.value && this.mask && this.$refs.input.inputmask) {

        if (!this.$refs.input.inputmask.isComplete()) {
          this.$emit("update:valid", false);
          return;
        }

      }

      this.$emit("update:valid", true);
    },

  },

  mounted() {
    this.checkValid();
  },
};
</script>

<style lang="scss"></style>
